import { mapActions, mapGetters } from 'vuex'
import validationMixin from '@/mixins/validation'
import { debounce, snakeCase } from 'lodash'

export default {
  name: 'users-admins-settings',
  mixins: [validationMixin],
  data () {
    return {
      valid: false,
      payload: {
        coursesLanguages: [],
        timezoneId: ''
      },
      search: '',
      searchInput: ''
    }
  },
  computed: {
    ...mapGetters({
      settings: 'userAdmins/settings',
      isLoading: 'userAdmins/isLoading',
      basicCountries: 'setting/basicCountries',
      timezones: 'setting/timezones',
      timezonesLoading: 'setting/timezonesLoading'
    })
  },
  watch: {
    settings: {
      handler () {
        if (this.settings) {
          this.setData()
          const namedActiveTimezone = this.settings?.timezone.data.hasOwnProperty('id') ? this.settings.timezone.data.name : ''
          this.fetchTimezones({
            keywords: namedActiveTimezone
          })
        }
      },
      deep: true
    }
  },
  created () {
    if (this.settings) {
      const namedActiveTimezone = this.settings?.timezone.data.hasOwnProperty('id') ? this.settings.timezone.data.name : ''
      this.changeInputTimezones(namedActiveTimezone)
    }
    this.setData()
  },
  methods: {
    ...mapActions({
      update: 'userAdmins/UPDATE_SETTINGS',
      fetchTimezones: 'setting/GET_TIMEZONES'
    }),
    createRequestPayload () {
      const formData = {}
      Object.entries(this.payload).forEach(e => {
        formData[snakeCase(e[0])] = e[1]
      })
      formData.userId = this.$route.params.id
      formData._method = 'patch'
      return formData
    },
    setData () {
      if (this.settings) {
        Object.assign(this.payload, this.settings)
        this.search = this.settings?.timezone.data
      }
    },
    changeInputTimezones: debounce(function (val) {
      if (!val) return
      if (this.search.name !== val) {
        this.fetchTimezones({
          keywords: val
        })
      }
    }, 500),
    autoCompleteTimezoneChanged (e) {
      this.payload.timezoneId = e?.id || ''
      this.search = e
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.update(this.createRequestPayload()).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    }
  }
}
